import { pinnedLayerDataItemToCss } from '@wix/thunderbolt-becky-root/src/functionLibraryExtensions'
import { Component } from '@wix/thunderbolt-becky-types'
import { createCompNode } from '@wix/thunderbolt-catharsis'
import { mapValues, merge } from 'lodash'
import { componentNodeRefs } from '../refs'
import { BreakpointsVariantsToSelectorsMap, SelectorObj } from '../types'

export const pinnedLayer = createCompNode({
	getDependencies: (component: Component) => componentNodeRefs.compLayouts(component),
	toViewItem: (__, breakpointsToLayouts): BreakpointsVariantsToSelectorsMap | null => {
		if (!breakpointsToLayouts) {
			return null
		}

		const css = mapValues(breakpointsToLayouts, (variantsToLayouts) =>
			mapValues(variantsToLayouts, (layoutDataItems) => {
				const itemsCss = layoutDataItems.map((item) =>
					item.type === 'FixedItemLayout' ? pinnedLayerDataItemToCss(item) : null
				)
				const mergedCss: SelectorObj = merge({}, ...itemsCss)
				return mergedCss
			})
		)

		return css
	},
})
