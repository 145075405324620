import { Component } from '@wix/thunderbolt-becky-types'
import { createCompNode } from '@wix/thunderbolt-catharsis'
import { componentNodeRefs } from '../refs'
import { ComponentCss } from '../types'

export const componentCss = createCompNode({
	getDependencies: (component: Component) => ({
		breakpointsOrder: componentNodeRefs.breakpointsOrder(component),
		pinnedLayer: componentNodeRefs.pinnedLayer(component),
		responsiveLayout: componentNodeRefs.responsiveLayout(component),
		variants: componentNodeRefs.variants(component),
	}),
	toViewItem: (__, deps): ComponentCss => deps,
})
