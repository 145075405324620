import { createDataRef, createCompRef } from '@wix/thunderbolt-catharsis'
import { CatharsisCss, ComponentNodeRefs } from './types'

export const removeHash = (value: string) => (value && value[0] === '#' ? value.slice(1) : value)

const createDataRefForSpecificMap = (name: string) => <T>(id: string, defaultValue?: T) =>
	createDataRef<T>(name, id, defaultValue)

export const dataMapsRefs = {
	document_data: createDataRefForSpecificMap('document_data'),
	design_data: createDataRefForSpecificMap('design_data'),
	layout_data: createDataRefForSpecificMap('layout_data'),
	variants_data: createDataRefForSpecificMap('variants_data'),
}

export const componentNodeRefs: ComponentNodeRefs = {
	compLayouts: (component) => createCompRef<CatharsisCss['compLayouts']>('compLayouts', component.id),
	breakpointsOrder: (component) => createCompRef<CatharsisCss['breakpointsOrder']>('breakpointsOrder', component.id),
	pinnedLayer: (component) => createCompRef<CatharsisCss['pinnedLayer']>('pinnedLayer', component.id),
	responsiveLayout: (component) => createCompRef<CatharsisCss['responsiveLayout']>('responsiveLayout', component.id),
	variants: (component) => createCompRef<CatharsisCss['variants']>('variants', component.id),
}
