import _ from 'lodash'
import { RuntimeDal } from '@wix/viewer-manager-interface'
import type { DefaultViewerAPI, GetViewerAPI } from 'thunderbolt-viewer-manager-types'

// TODO remove methods once they are implemented
export const createDefaultViewerAPI: GetViewerAPI = () => {
	const defaultViewerAPI: DefaultViewerAPI = {
		viewerConfig: { viewerName: 'tb', viewerVersion: '0' },
		platformDsModel: {
			getPlatformDsModel: () => ({}),
			updatePlatformDsModel: _.noop,
		},
		legacy: {
			updateBiData: _.noop,
			reportBI: _.noop,
			reportBIError: _.noop,
		},
		selectiveDownload: {
			downloadCompClasses: () => Promise.resolve(),
		},
		// ne need to implement currently: https://wix.slack.com/archives/C01AKK70JNA/p1620213667123300?thread_ts=1620198426.111700&cid=C01AKK70JNA
		wixCode: {
			setWixCodeDsModel: _.noop,
			getWixCodeDsModel: () => ({}),
		},
		ghostables: {
			getGhostStructure: () => ({}),
			setGhostStructure: _.noop,
			getGhostControllers: () => ({}),
			setGhostControllers: _.noop,
		},
		platform: {
			getAppInstance: () => ({}),
			getRuntimeDal: () => ({} as RuntimeDal),
			reloadAppsContainer: _.noop,
			triggerAppStudioWidgetOnPropsChanged: _.noop,
			flushDsBeforeLoadMessagesQueue: _.noop,
			registerToAppInstanceUpdate: _.noop,
			registerToNotifyApplicationRequestFromViewerWorker: _.noop,
			getComponentsByPageIdForWixCode: () => ({}),
		},
		media: {
			registerStateChange: _.noop,
			unregisterStateChange: _.noop,
		},
		members: {
			getMemberDetailsInPreview: _.noop,
			showLoginDialog: () => Promise.resolve(),
			showSignUpDialog: () => Promise.resolve(),
			hideAuthDialog: () => Promise.resolve(),
		},
		stylable: {
			getStylableEditorInstance: _.noop,
			setQuickChanges: _.noop,
			revertQuickChanges: _.noop,
			forceState: _.noop,
			revertForceState: _.noop,
			mergeStylesheets: () => '',
		},
	}

	return defaultViewerAPI
}
