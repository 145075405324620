import { AdditionalLayoutProperties } from '@wix/thunderbolt-becky-root'
import { layoutDataItemToCss } from '@wix/thunderbolt-becky-root/src/functionLibraryExtensions'
import { Component, ContainerLayouts, LayoutDataItems } from '@wix/thunderbolt-becky-types'
import { createCompNode, envRefs } from '@wix/thunderbolt-catharsis'
import { mapValues, merge, set } from 'lodash'
import { componentNodeRefs } from '../refs'
import { BreakpointsVariantsToLayoutsMap, ResponsiveLayoutResult } from '../types'

const COMPONENT_DISPLAY_VARIABLE = '--display'
const CONTAINER_DISPLAY_VARIABLE = '--container-display'
const LAYOUT_DISPLAY_VARIABLE = '--l_display'

const CONTAINER_LAYOUTS_TYPES = new Set([
	'GridContainerLayout',
	'FlexContainerLayout',
	'OrganizerContainerLayout',
	'StackContainerLayout',
])

const PATHS = {
	POSITION: ['default', '', 'component', 'position'],
	ASPECT_RATIO: ['default', '', 'component', 'aspect-ratio'],
	DISPLAY: ['default', '', 'component', 'display'],
	DISPLAY_VAR: ['default', '', 'component', '--display'],
}

const CONTAINER_SELECTORS = ['component-one-cell-grid', 'container']

const CONTAINER_DISPLAY = `var(${LAYOUT_DISPLAY_VARIABLE},var(${CONTAINER_DISPLAY_VARIABLE}))`
const COMPONENT_DISPLAY = `var(${LAYOUT_DISPLAY_VARIABLE},var(${COMPONENT_DISPLAY_VARIABLE}))`

const COMPONENTS_WITH_WRAPPER_LAYERS = new Set(['Page', 'Repeater', 'ResponsivePopupPage', 'MenuContainer'])

const getContainerLayouts = (breakpointToLayouts: BreakpointsVariantsToLayoutsMap) =>
	Object.values(breakpointToLayouts)
		.flatMap((x) => Object.values(x).flatMap((y) => y))
		.filter((l) => CONTAINER_LAYOUTS_TYPES.has(l.type)) as Array<ContainerLayouts>

const hasNonVisibleOverflow = (item: ContainerLayouts) =>
	(item.overflowY && item.overflowY !== 'visible') || (item.overflowX && item.overflowX !== 'visible')

const shouldNotOmitWrapperLayers = (
	hasOverflow: boolean,
	component: Readonly<Component>,
	containerLayouts: Array<ContainerLayouts>
) =>
	hasOverflow ||
	COMPONENTS_WITH_WRAPPER_LAYERS.has(component.componentType) ||
	containerLayouts.some((l) => l.padding && Object.values(l.padding).some((prop) => prop.type === 'percentage')) ||
	containerLayouts.some((l) => l.type === 'GridContainerLayout' && l.rows.some((r) => r.type === 'percentage'))

export const responsiveLayout = createCompNode({
	getDependencies: (component: Component) =>
		component.layoutQuery
			? {
					breakpointsVariantsToLayouts: componentNodeRefs.compLayouts(component),
					renderScrollSnap: envRefs.renderScrollSnap,
					renderSticky: envRefs.renderSticky,
			  }
			: null,
	toViewItem: (component, deps): ResponsiveLayoutResult => {
		if (!deps) {
			return null
		}
		const { breakpointsVariantsToLayouts, renderScrollSnap, renderSticky } = deps

		if (!breakpointsVariantsToLayouts) {
			return { css: {}, shouldOmitWrapperLayers: false }
		}
		const containerLayouts = getContainerLayouts(breakpointsVariantsToLayouts)

		const hasOverflow = containerLayouts.some(hasNonVisibleOverflow)

		const shouldOmitWrapperLayers = !shouldNotOmitWrapperLayers(hasOverflow, component, containerLayouts)

		const options: AdditionalLayoutProperties = {
			hasOverflow,
			renderScrollSnap,
			renderSticky,
			shouldOmitWrapperLayers,
			newResponsiveLayout: true,
		}

		const layoutToCss = (layout: LayoutDataItems) => layoutDataItemToCss(layout, options)
		const css = mapValues(breakpointsVariantsToLayouts, (variantInSpecificBreakpoint) => {
			return mapValues(variantInSpecificBreakpoint, (v) => {
				const itemsCss = v.map(layoutToCss)
				const mergedCss = merge({}, ...itemsCss)

				for (const selector of CONTAINER_SELECTORS) {
					if (mergedCss?.[selector]?.display) {
						mergedCss[selector][CONTAINER_DISPLAY_VARIABLE] = mergedCss[selector].display
						mergedCss[selector].display = CONTAINER_DISPLAY
					}
				}

				const position = mergedCss?.item?.position
				if (!position) {
					return mergedCss
				}

				if (position === 'sticky') {
					mergedCss.item['--sticky-position'] = position
				} else {
					mergedCss.item['--position'] = position
					mergedCss.item['--sticky-position'] = 'unset'
				}
				delete mergedCss.item.position
				return mergedCss
			})
		})

		set(css, PATHS.POSITION, 'var(--sticky-position,var(--position))')
		set(css, PATHS.ASPECT_RATIO, 'var(--aspect-ratio)')

		// allow leaf components to specify their own display via css variable
		if (component.type === 'Component') {
			set(css, PATHS.DISPLAY, COMPONENT_DISPLAY)
		}

		return { css, shouldOmitWrapperLayers }
	},
})
