import {
	Catharsis,
	CatharsisOps,
	getCatharsis,
	getInvalidationHandler,
	MegaStoreWithSubscriptions,
} from '@wix/thunderbolt-catharsis'
import { defaultSchemas as schemas } from './defaultSchemas'
import { componentNodes, dataNodes } from '@wix/thunderbolt-catharsis-extensions'

const CATHARSIS_NOOPS: CatharsisOps = {
	updateData: () => {},
	updateStructure: () => {},
	updateEnvironment: () => {},
}

const NOOP_CATHARSIS: Catharsis = {
	transaction: (update) => update(CATHARSIS_NOOPS),
}

export const getEditorCatharsis = (
	isOpen: boolean
): { catharsis: Catharsis; catharsisMegaStore: MegaStoreWithSubscriptions | null } => {
	if (!isOpen) {
		return {
			catharsis: NOOP_CATHARSIS,
			catharsisMegaStore: null,
		}
	}

	const catharsisMegaStore = new MegaStoreWithSubscriptions()
	const onInvalidation = getInvalidationHandler(catharsisMegaStore)
	const catharsis = getCatharsis({ onInvalidation, extensions: { componentNodes, dataNodes, schemas } })
	return { catharsis, catharsisMegaStore }
}
