import { createMaterializer } from '@wix/materializer'
import { set } from 'lodash'
import { Batched, Catharsis, CatharsisArgs, UpdateData, UpdateEnvironment, UpdateStructure } from './catharsis.types'
import { ROOT } from '../refs/constants'
import { createRefResolver } from '../refs/schemaRefResolver'

const EMPTY_REMOVED: Array<Array<string>> = []
const EMPTY_SCHEMA = { schema: undefined, removed: EMPTY_REMOVED }

const defaultToViewItem = (rawItem: any, refs: any) => {
	const result = { ...rawItem, ...refs }
	delete result.metaData
	return result
}

export const getCatharsis = ({ onInvalidation, extensions }: CatharsisArgs): Catharsis => {
	const rawModel: Record<string, any> = {}
	const materializer = createMaterializer({
		transform: (value: any, path: Array<string>) => {
			const [namespace, aspect, compId] = path
			if (namespace === 'data') {
				const rawItem = rawModel[namespace][aspect][compId]
				if (typeof rawItem === 'undefined') {
					return undefined
				}

				const toViewItem = extensions.dataNodes[rawItem.type]?.toViewItem ?? defaultToViewItem
				return toViewItem(rawItem, value?.schema, value?.custom)
			}

			if (namespace === ROOT) {
				const component = rawModel.structure[compId]
				return component && extensions.componentNodes[aspect].toViewItem(component, value)
			}

			return value
		},
	})

	const getRefsForDataItem = extensions.schemas ? createRefResolver(extensions.schemas) : () => null

	const updateData: Batched<UpdateData> = (update) => (type, id, dataItem) => {
		set(rawModel, ['data', type, id], dataItem)
		if (!dataItem) {
			update('data', type, id, null, { schema: undefined, removed: EMPTY_REMOVED })
			return
		}
		const schemaRefs = getRefsForDataItem(dataItem, type)
		const customDeps = extensions.dataNodes[dataItem.type]?.getDependencies?.(dataItem)
		const dependencies = schemaRefs || customDeps ? { schema: schemaRefs, custom: customDeps } : null
		update('data', type, id, dependencies, { schema: dependencies, removed: EMPTY_REMOVED })
	}

	const updateStructure: Batched<UpdateStructure> = (update) => (id, component) => {
		set(rawModel, ['structure', id], component)
		for (const structureCalculator in extensions.componentNodes) {
			const value = component && extensions.componentNodes[structureCalculator].getDependencies(component)
			update(ROOT, structureCalculator, id, value, {
				schema: value,
				removed: EMPTY_REMOVED,
			})
		}
	}

	const updateEnvironment: Batched<UpdateEnvironment> = (update) => (environment) => {
		for (const envType in environment) {
			for (const key in environment[envType]) {
				update('environment', envType, key, environment[envType][key], EMPTY_SCHEMA)
			}
		}
	}

	const transaction: Catharsis['transaction'] = (update) => {
		const invalidations = materializer.batch((batchUpdate) =>
			update({
				updateData: updateData(batchUpdate),
				updateStructure: updateStructure(batchUpdate),
				updateEnvironment: updateEnvironment(batchUpdate),
			})
		) as Array<Array<string>>

		for (const invalidation of invalidations) {
			onInvalidation(invalidation, materializer.get)
		}
	}

	return {
		transaction,
	}
}
