import _ from 'lodash'
import React, { ReactElement } from 'react'
import type { GetViewerApiParams } from 'thunderbolt-viewer-manager-types'
import { ComponentsLoaderSymbol, IComponentsLoader } from '@wix/thunderbolt-components-loader'
import { ILogger, IStructureStore, Structure as StructureSym } from '@wix/thunderbolt-symbols'
import { createPromise } from '@wix/thunderbolt-commons'
import type { DsApiFactoryEnv, DsApis } from './getDsApis'
import { tbFragmentProps } from './types'

declare const window: DsApiFactoryEnv['window']
export type DSViewerApiFactoryParams = GetViewerApiParams

export type GetViewerFragment = (
	hostReact: typeof React,
	serviceTopology: GetViewerApiParams['serviceTopology'],
	props: tbFragmentProps
) => Promise<ReactElement>

export type GetViewerFragments = (
	hostReact: typeof React,
	serviceTopology: GetViewerApiParams['serviceTopology'],
	props: tbFragmentProps
) => Promise<Array<ReactElement>>

export const VIEWER_FRAGMENT_RENDERER_INTERACTIONS = {
	GET_VIEWER_FRAGMENT_TOTAL: 'get_viewer_fragment_total',
	INIT_VIEWER_FRAGMENT: 'init_viewer_fragment',
	VIEWER_FRAGMENT_ADD_DATA: 'viewer_fragment_add_data',
	VIEWER_FRAGMENT_FULL_RENDER: 'viewer_fragment_full_render',
	VIEWER_FRAGMENT_RENDER: 'viewer_fragment_render',
	VIEWER_FRAGMENT_LOAD_COMPS: 'viewer_fragment_load_comps',
	VIEWER_FRAGMENT_LOAD_STYLES: 'viewer_fragment_load_styles',
}

// We need this CSS in the preview frame for panel builder but this css breaks the editor css
// We don't need this css for miniSites (for now) so we are not copying it.
const isDesignSystemCssHref = (href: string) => href.includes('rb_dsgnsys')
const isViewerCssHref = (href: string) => href.includes('viewer.css')
const isMainDsCssHref = (href: string) => href.includes('main-ds')

const shouldExcludeStyleSheet = (href: string) =>
	isDesignSystemCssHref(href) || isMainDsCssHref(href) || isViewerCssHref(href)

const VIEWER_ADDITIONAL_CSS = 'VIEWER_ADDITIONAL_CSS'
const pointerEventsCss =
	'[data-mesh-id$="-gridContainer"] > *, [data-mesh-id$="-rotated-wrapper"] > *, [data-mesh-id$="inlineContent"] > :not([data-mesh-id$="-gridContainer"]) { pointer-events: auto; }'

const addViewerAdditionalCss = () => {
	const styleTag = document.createElement('style')
	styleTag.id = VIEWER_ADDITIONAL_CSS
	styleTag.appendChild(document.createTextNode(pointerEventsCss))
	window.top!.document.head.appendChild(styleTag)
}

const loadStylesForFragment = () => {
	const loadStylesPromises: Array<Promise<any>> = []
	document.head.querySelectorAll('link[rel="stylesheet"]').forEach((e) => {
		const href = e.getAttribute('href')

		if (!href || shouldExcludeStyleSheet(href)) {
			return
		}
		if (!window.top!.document.head.querySelector(`link[href="${href}"]`)) {
			const newNode = e.cloneNode()
			const { promise, resolver } = createPromise()
			loadStylesPromises.push(promise)
			// @ts-ignore
			newNode.onload = resolver
			window.top!.document.head.appendChild(newNode)
		}
	})
	if (!window.top!.document.getElementById(VIEWER_ADDITIONAL_CSS)) {
		addViewerAdditionalCss()
	}

	return loadStylesPromises
}

export const buildGetViewerFragment = (
	getDsApisPromise: () => Promise<DsApis>,
	logger: ILogger
): GetViewerFragment => async (hostReact, serviceTopology, props) => {
	logger.interactionStarted(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.GET_VIEWER_FRAGMENT_TOTAL)
	logger.interactionStarted(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.INIT_VIEWER_FRAGMENT)
	const { render, getViewerAPI, getModule } = await getDsApisPromise()
	const viewerAPI = getViewerAPI()
	const { structure, data, onReady, rootCompIds, extraEventsHandlers } = props
	logger.interactionEnded(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.INIT_VIEWER_FRAGMENT)
	logger.interactionStarted(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.VIEWER_FRAGMENT_ADD_DATA)
	viewerAPI.actions.runInBatch(() => {
		Object.entries(data).forEach(([mapName, mapData]) =>
			Object.entries(mapData).forEach(([itemId, itemData]) => {
				// @ts-ignore
				viewerAPI.data.updateData(mapName, itemId, itemData)
			})
		)

		Object.entries(structure).forEach(([compId, compStructure]) => {
			compStructure.metaData = { pageId: 'masterPage' }
			viewerAPI.structure.updateStructure(compId, compStructure)
		})
		const siteRoot = viewerAPI.structure.getStructure('site-root')
		viewerAPI.structure.updateStructure('site-root', {
			...siteRoot,
			components: rootCompIds,
		})
	})
	logger.interactionEnded(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.VIEWER_FRAGMENT_ADD_DATA)

	const componentsLoader = getModule<IComponentsLoader>(ComponentsLoaderSymbol)
	const structureStore = getModule<IStructureStore>(StructureSym)

	const mainStructure = structureStore.get('main_MF')
	structureStore.update({
		main_MF: {
			...mainStructure,
			components: _.without(mainStructure.components, 'SCROLL_TO_BOTTOM', 'SCROLL_TO_TOP'),
		},
	})

	logger.interactionStarted(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.VIEWER_FRAGMENT_LOAD_COMPS)
	const loadCompPromise = componentsLoader.loadComponents(structureStore.getEntireStore())

	logger.interactionStarted(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.VIEWER_FRAGMENT_FULL_RENDER)
	return hostReact.createElement('div', {
		style: { position: 'relative' },
		ref: async (ref: HTMLElement) => {
			if (ref) {
				await loadCompPromise
				logger.interactionEnded(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.VIEWER_FRAGMENT_LOAD_COMPS)

				logger.interactionStarted(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.VIEWER_FRAGMENT_RENDER)
				await render(ref)
				logger.interactionEnded(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.VIEWER_FRAGMENT_RENDER)

				logger.interactionStarted(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.VIEWER_FRAGMENT_LOAD_STYLES)
				const stylesPromises = loadStylesForFragment()
				await Promise.all([...stylesPromises])
				logger.interactionEnded(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.VIEWER_FRAGMENT_LOAD_STYLES)

				logger.interactionEnded(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.VIEWER_FRAGMENT_FULL_RENDER)
				logger.interactionEnded(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.GET_VIEWER_FRAGMENT_TOTAL)
				onReady()
			}
		},
		...extraEventsHandlers,
	})
}

export const buildGetViewerFragments = (
	getDsApisPromise: () => Promise<DsApis>,
	logger: ILogger
): GetViewerFragments => async (hostReact, serviceTopology, props) => {
	const startedAt = performance.now()
	console.log('//TB-MS: Starting to build viewer fragment')
	logger.interactionStarted(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.GET_VIEWER_FRAGMENT_TOTAL)
	logger.interactionStarted(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.INIT_VIEWER_FRAGMENT)
	const { render, getViewerAPI, getModule, initCustomElements } = await getDsApisPromise()
	initCustomElements()
	console.log(`//TB-MS: Finished getting DS APIs, took: ${performance.now() - startedAt}`)
	const viewerAPI = getViewerAPI()
	console.log(`//TB-MS: Finished getting Viewer API, took: ${performance.now() - startedAt} (from beginning)`)
	const { structure, data, onReady, rootCompIds, extraEventsHandlers } = props
	console.log(`//TB-MS: root comp ids: ${rootCompIds}`)
	logger.interactionEnded(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.INIT_VIEWER_FRAGMENT)
	logger.interactionStarted(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.VIEWER_FRAGMENT_ADD_DATA)
	viewerAPI.actions.runInBatch(() => {
		Object.entries(data).forEach(([mapName, mapData]) =>
			Object.entries(mapData).forEach(([itemId, itemData]) => {
				if (
					itemData &&
					typeof itemData === 'object' &&
					(itemData as any).textTheme &&
					itemId === 'THEME_DATA'
				) {
					;(itemData as any).textTheme = Object.values((itemData as any).textTheme as any)
				}

				// @ts-ignore
				viewerAPI.data.updateData(mapName, itemId, itemData)
			})
		)

		Object.entries(structure).forEach(([compId, compStructure]) => {
			compStructure.metaData = { pageId: 'masterPage' }
			viewerAPI.structure.updateStructure(compId, compStructure)
		})
		const siteRoot = viewerAPI.structure.getStructure('site-root')
		// const mainMF = viewerAPI.structure.getStructure('main')
		rootCompIds.forEach((compId) => {
			viewerAPI.structure.updateStructure(`site-root-${compId}`, {
				...siteRoot,
				id: `site-root-${compId}`,
				components: [compId],
			})
		})
	})
	console.log(
		`//TB-MS: Finished updating structure and data in batch, took: ${
			performance.now() - startedAt
		} (from beginning)`
	)
	logger.interactionEnded(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.VIEWER_FRAGMENT_ADD_DATA)

	const componentsLoader = getModule<IComponentsLoader>(ComponentsLoaderSymbol)
	const structureStore = getModule<IStructureStore>(StructureSym)

	const mainStructure = structureStore.get('main_MF')
	rootCompIds.forEach((compId) => {
		const newComps = [...mainStructure.components].map((id) => (id === 'site-root' ? `site-root-${compId}` : id))
		structureStore.update({
			[`main_MF-${compId}`]: {
				...mainStructure,
				components: _.without(newComps, 'SCROLL_TO_BOTTOM', 'SCROLL_TO_TOP'),
			},
		})
	})

	logger.interactionStarted(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.VIEWER_FRAGMENT_LOAD_COMPS)
	const loadCompPromise = componentsLoader.loadComponents(structureStore.getEntireStore())

	await loadCompPromise
	logger.interactionEnded(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.VIEWER_FRAGMENT_LOAD_COMPS)
	console.log(
		`//TB-MS: Finished loading components, took ${performance.now() - startedAt}ms (from beginning of the build)`
	)

	logger.interactionStarted(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.VIEWER_FRAGMENT_FULL_RENDER)
	console.log(`//TB-MS: Finished building fragment, returning fragment, took: ${performance.now() - startedAt}ms`)
	return rootCompIds.map((rootCompId) =>
		hostReact.createElement('div', {
			style: { position: 'relative' },
			ref: async (ref: HTMLElement) => {
				if (ref) {
					const startedRenderAt = performance.now()
					console.log(
						`//TB-MS: Starting rendering the fragment, took ${
							performance.now() - startedAt
						}ms (from beginning of the build)`
					)

					logger.interactionStarted(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.VIEWER_FRAGMENT_RENDER)
					await render(ref, `main_MF-${rootCompId}`)
					console.log(
						`//TB-MS: Finished render phase, took: ${
							performance.now() - startedRenderAt
						} (from beginning of render)`
					)
					logger.interactionEnded(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.VIEWER_FRAGMENT_RENDER)

					logger.interactionStarted(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.VIEWER_FRAGMENT_LOAD_STYLES)
					const stylesPromises = loadStylesForFragment()
					await Promise.all([...stylesPromises])
					logger.interactionEnded(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.VIEWER_FRAGMENT_LOAD_STYLES)

					logger.interactionEnded(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.VIEWER_FRAGMENT_FULL_RENDER)
					logger.interactionEnded(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.GET_VIEWER_FRAGMENT_TOTAL)
					onReady()
					console.log(
						`//TB-MS: Finished rendering fragment, took ${
							performance.now() - startedAt
						}ms (from beginning of the build)`
					)
					console.log(`//TB-MS: The rendering phase took ${performance.now() - startedRenderAt}ms`)
					console.log(`//TB-MS-TIMES: Finished exactly at: ${performance.now()}`)
				}
			},
			...extraEventsHandlers,
		})
	)
}
