type OverrideContext = string
type Subscriber = (id: string, value: any) => void
type Unsubscribe = () => void

export interface IStore {
	getById<T = any>(id: string): T
	updateById(id: string, data: any): void
}

export interface IChildStores<TStore> {
	getChildStore(context: OverrideContext): TStore
}

const subscriptions = new WeakMap<IStore, Map<string, Set<Subscriber>>>()
const childStores = new WeakMap<IStore, Map<string, IStore>>()

const invokeSubscribers = (store: IStore, id: string): void => {
	const mySubscriptions = subscriptions.get(store)!
	const mySubscribers = mySubscriptions.get(id)
	if (mySubscribers) {
		const value = store.getById(id)
		mySubscribers.forEach((callback) => callback(id, value))
	}

	const children = childStores.get(store)
	if (children) {
		children.forEach((childStore) => {
			invokeSubscribers(childStore, id)
		})
	}
}

export class PrivateMegaStore<TStore extends IStore> implements IStore, IChildStores<TStore> {
	private readonly myStore: Map<string, {}> = new Map()

	constructor(private readonly parentStore?: TStore) {
		childStores.set(this, new Map())
	}

	getById<T = unknown>(id: string): T {
		const parentValue = this.parentStore?.getById(id)
		const myValue = this.myStore.get(id)
		return parentValue ? { ...parentValue, ...myValue } : (myValue as T)
	}

	getChildStore(contextId: string): TStore {
		let childStore = childStores.get(this)?.get(contextId) as TStore
		if (!childStore) {
			// @ts-ignore
			childStore = new this.constructor(this) as TStore
			childStores.get(this)!.set(contextId, childStore)
		}
		return childStore
	}

	updateById<T = unknown>(id: string, data: T): void {
		this.myStore.set(id, this.myStore.has(id) ? { ...this.myStore.get(id), ...data } : data)
	}
}

export class MegaStore extends PrivateMegaStore<MegaStore> {}

export class MegaStoreWithSubscriptions extends PrivateMegaStore<MegaStoreWithSubscriptions> {
	constructor(parentStore?: MegaStoreWithSubscriptions) {
		super(parentStore)
		subscriptions.set(this, new Map())
	}

	subscribeById(id: string, callback: Subscriber): Unsubscribe {
		const mySubscriptions = subscriptions.get(this)!
		if (!mySubscriptions.has(id)) {
			mySubscriptions.set(id, new Set())
		}
		mySubscriptions.get(id)!.add(callback)
		return () => {
			mySubscriptions.get(id)!.delete(callback)
		}
	}

	updateById<T = unknown>(id: string, data: T): void {
		super.updateById(id, data)
		invokeSubscribers(this, id)
	}
}
