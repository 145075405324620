import { Component } from '@wix/thunderbolt-becky-types'
import { isVariantRelation } from './responsiveLayoutUtils'
import { dataMapsRefs } from '../refs'
import { createCompNode } from '@wix/thunderbolt-catharsis'
import { CompVariants } from '../types'

export const variants = createCompNode({
	getDependencies: (component: Component) =>
		component.layoutQuery ? dataMapsRefs.layout_data(component.layoutQuery) : null,
	toViewItem: (__, layoutRefArray): CompVariants | null => {
		if (!layoutRefArray || !Array.isArray(layoutRefArray)) {
			return null
		}

		return layoutRefArray.reduce<CompVariants>((acc, val) => {
			const isVariant = isVariantRelation(val)

			if (isVariant) {
				for (const variant of val.variants) {
					acc[variant.id] = variant
				}
			}

			return acc
		}, {})
	},
})
