import { createRef } from '@wix/materializer'
import { BaseDataItem } from '@wix/thunderbolt-becky-types'
import _ from 'lodash'
import { WixReferenceAnalysis } from '@wix/document-services-types'
import { removeHash } from './refsFactory'

export const createRefResolver = (refData: WixReferenceAnalysis) => (dataItem: BaseDataItem, mapName: string) => {
	if (!(dataItem.type in refData[mapName])) {
		return null
	}

	const refs = refData[mapName][dataItem.type]
	return refs.reduce((acc, { path, referencedMap }) => {
		const referencedId = _.get(dataItem, path)
		if (!referencedId) {
			return acc
		}

		const value = Array.isArray(referencedId)
			? referencedId.map((id) => createRef(['data', referencedMap, removeHash(id)]))
			: createRef(['data', referencedMap, removeHash(referencedId)])

		_.set(acc, path, value)

		return acc
	}, {})
}
