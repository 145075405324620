import { MegaStore } from '../stores/megaStore'
import { CatharsisArgs } from './catharsis.types'

export const getInvalidationHandler = (megaStore: MegaStore): CatharsisArgs['onInvalidation'] => (path, get) => {
	const [, aspect, compId] = path

	if (aspect === 'componentCss') {
		megaStore.getChildStore(aspect).updateById(compId, get(path))
	}
}
