import { BaseDataItem, UnresolvedVariantRelation } from '@wix/thunderbolt-becky-types'
import { createDataNode, VariantRelationViewDataItem, VariantViewDataItem } from '@wix/thunderbolt-catharsis'

export const VariantRelation = createDataNode({
	toViewItem: (
		dataItem: UnresolvedVariantRelation,
		{ to, variants }: { to: BaseDataItem; variants: Array<VariantViewDataItem> }
	): VariantRelationViewDataItem<any> => ({
		...dataItem,
		to,
		variants: variants.sort((a) => (a.type === 'BreakpointRange' ? -1 : 1)),
	}),
})
