import { Component } from '@wix/thunderbolt-becky-types'
import { createCompNode } from '@wix/thunderbolt-catharsis'
import { dataMapsRefs } from '../refs'

export const breakpointsOrder = createCompNode({
	getDependencies: (component: Component) =>
		// @ts-expect-error
		component.breakpointVariantsQuery ? dataMapsRefs.variants_data(component.breakpointVariantsQuery) : null,
	toViewItem: (__, breakpointsData) => breakpointsData,
})
