import _ from 'lodash'
import { ComponentsLoaderSymbol, IComponentsLoader } from '@wix/thunderbolt-components-loader'
import { ILogger, IStructureStore, Structure as StructureSym } from '@wix/thunderbolt-symbols'
import { DsApis } from './getDsApis'
import { VIEWER_FRAGMENT_RENDERER_INTERACTIONS } from './viewerFragmentRenderer'
import React from 'react'
import { loadStylesForFragment } from './fragmentsUtils'
import { IViewerFragmentsManager, tbFragmentProps } from './types'
import { Data, Structure } from '@wix/thunderbolt-becky-types'

export const viewerFragmentsManagerFactory = (
	getDsApisPromise: () => Promise<DsApis>,
	logger: ILogger
): IViewerFragmentsManager => {
	let apis: DsApis

	const initializeFragmentsManager = async () => {
		const startedAt = performance.now()
		console.log('//TB-MS: Starting to build viewer fragment')
		logger.interactionStarted(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.GET_VIEWER_FRAGMENT_TOTAL)
		logger.interactionStarted(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.INIT_VIEWER_FRAGMENT)
		apis = await getDsApisPromise()
		console.log(`//TB-MS: Finished getting DS APIs, took: ${performance.now() - startedAt}`)
	}

	const managerReadiness = initializeFragmentsManager()

	const updateDataAndStructure = async (props: tbFragmentProps) => {
		const startedAt = performance.now()
		console.log(`// TB-MS-TIMES: Starting updating and rendering exactly at ${startedAt}`)
		await managerReadiness
		console.log(`// TB-MS: Finished waiting for the manager, took ${performance.now() - startedAt}ms`)
		const { getViewerAPI, getModule } = apis
		const viewerAPI = getViewerAPI()
		console.log(`//TB-MS: Finished getting Viewer API, took: ${performance.now() - startedAt} (from beginning)`)
		const { structure, data, rootCompIds } = props
		console.log(`//TB-MS: root comp ids: ${rootCompIds}`)
		logger.interactionEnded(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.INIT_VIEWER_FRAGMENT)
		logger.interactionStarted(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.VIEWER_FRAGMENT_ADD_DATA)
		viewerAPI.actions.runInBatch(() => {
			Object.entries(data).forEach(([mapName, mapData]) =>
				Object.entries(mapData).forEach(([itemId, itemData]) => {
					/**
					 * Due to a problem with specific value of THEME_DATA, we need to change it's type
					 * from object to an array, it's an issue with how the Editor and DM sends us the data
					 * They need to investigate it and send us this value as an array, once this is done
					 * we can remove this condition
					 */
					if (
						itemData &&
						typeof itemData === 'object' &&
						(itemData as any).textTheme &&
						itemId === 'THEME_DATA'
					) {
						;(itemData as any).textTheme = Object.values((itemData as any).textTheme as any)
					}

					// @ts-ignore
					viewerAPI.data.updateData(mapName, itemId, itemData)
				})
			)

			Object.entries(structure).forEach(([compId, compStructure]) => {
				// TODO since master page container lives forever (really? cause we know it's cleanup up on navigation), we better put all components on some unique fake page id to avoid mem leaks.
				compStructure.metaData = { pageId: 'masterPage' }
				viewerAPI.structure.updateStructure(compId, compStructure)
			})
			const siteRoot = viewerAPI.structure.getStructure('site-root')
			rootCompIds.forEach((compId) => {
				viewerAPI.structure.updateStructure(`site-root-${compId}`, {
					...siteRoot,
					id: `site-root-${compId}`,
					components: [compId],
				})
			})
		})
		console.log(
			`//TB-MS: Finished updating structure and data in batch, took: ${
				performance.now() - startedAt
			} (from beginning)`
		)
		logger.interactionEnded(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.VIEWER_FRAGMENT_ADD_DATA)

		const componentsLoader = getModule<IComponentsLoader>(ComponentsLoaderSymbol)
		const structureStore = getModule<IStructureStore>(StructureSym)

		const mainStructure = structureStore.get('main_MF')
		rootCompIds.forEach((compId) => {
			const newComps = [...mainStructure.components].map((id) =>
				id === 'site-root' ? `site-root-${compId}` : id
			)
			structureStore.update({
				[`main_MF-${compId}`]: {
					...mainStructure,
					components: _.without(newComps, 'SCROLL_TO_BOTTOM', 'SCROLL_TO_TOP'),
				},
			})
		})

		logger.interactionStarted(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.VIEWER_FRAGMENT_LOAD_COMPS)
		const loadCompPromise = componentsLoader.loadComponents(structureStore.getEntireStore())

		await loadCompPromise
		logger.interactionEnded(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.VIEWER_FRAGMENT_LOAD_COMPS)
		console.log(
			`//TB-MS: Finished loading components, took ${
				performance.now() - startedAt
			}ms (from beginning of the build)`
		)

		logger.interactionStarted(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.VIEWER_FRAGMENT_FULL_RENDER)
		console.log(`//TB-MS: Finished building fragment, returning fragment, took: ${performance.now() - startedAt}ms`)
	}

	const getViewerFragments: IViewerFragmentsManager['getViewerFragments'] = (hostReact, serviceTopology, props) => {
		const { onReady, rootCompIds, extraEventsHandlers, structure, data } = props

		const { render } = apis
		const startedAt = performance.now()
		console.log(`//TB-MS-TIMES: Starting render exactly at: ${startedAt}`)
		return {
			removeFragments: getRemoveFragments(structure, data, rootCompIds),
			fragments: rootCompIds.map((rootCompId) =>
				hostReact.createElement('div', {
					style: { position: 'relative' },
					ref: async (ref: HTMLElement) => {
						if (ref) {
							const startedRenderAt = performance.now()
							console.log(
								`//TB-MS: (${rootCompId}) Starting rendering the fragment, took ${
									performance.now() - startedAt
								}ms (from beginning of the build)`
							)

							logger.interactionStarted(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.VIEWER_FRAGMENT_RENDER)
							// TODO consider exporting the <App /> component instead of rendering like this
							await render(ref, `main_MF-${rootCompId}`)
							console.log(
								`//TB-MS: (${rootCompId}) Finished render phase, took: ${
									performance.now() - startedRenderAt
								} (from beginning of render)`
							)
							logger.interactionEnded(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.VIEWER_FRAGMENT_RENDER)

							logger.interactionStarted(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.VIEWER_FRAGMENT_LOAD_STYLES)
							await loadStylesForFragment()
							logger.interactionEnded(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.VIEWER_FRAGMENT_LOAD_STYLES)

							logger.interactionEnded(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.VIEWER_FRAGMENT_FULL_RENDER)
							logger.interactionEnded(VIEWER_FRAGMENT_RENDERER_INTERACTIONS.GET_VIEWER_FRAGMENT_TOTAL)
							onReady()
							console.log(
								`//TB-MS: (${rootCompId}) Finished rendering fragment, took ${
									performance.now() - startedAt
								}ms (from beginning of the build)`
							)
							console.log(
								`//TB-MS: (${rootCompId}) The rendering phase took ${
									performance.now() - startedRenderAt
								}ms`
							)
							console.log(`//TB-MS-TIMES: Finished exactly at: ${performance.now()}`)
						}
					},
					...extraEventsHandlers,
				})
			),
		}
	}

	const getMultipleViewerFragments = async (
		hostReact: typeof React,
		serviceTopology: any,
		props: tbFragmentProps
	) => {
		await updateDataAndStructure(props)
		return getViewerFragments(hostReact, serviceTopology, props)
	}

	const getRemoveFragments = (structure: Structure, data: Data, rootCompIds: Array<string>) => () => {
		const { getViewerAPI } = apis
		const viewerAPI = getViewerAPI()

		Object.entries(data).forEach(([mapName, mapData]) =>
			Object.entries(mapData).forEach(([itemId]) => {
				// @ts-ignore
				viewerAPI.data.updateData(mapName, itemId, undefined)
			})
		)

		Object.entries(structure).forEach(([compId]) => {
			// @ts-ignore
			viewerAPI.structure.updateStructure(compId, undefined)
		})

		rootCompIds.forEach((compId) => {
			// @ts-ignore
			viewerAPI.structure(`site-root-${compId}`, undefined)
			// @ts-ignore
			viewerAPI.structure(`main_MF-${compId}`, undefined)
		})
	}

	return {
		updateDataAndStructure,
		getViewerFragments,
		getMultipleViewerFragments,
	}
}
