/*
This code is unfortunately a duplicate of the code running in public flow.
We need to require a polyfill named 'native-shim' before loading our components code, and
that only happens on @wix/wix-custom-element module, inside the init function, so we had
to call initCustomElement prior to the loading of our component (line 94 in getDsApis.ts)
and we can't share this code under thunderbolt-commons because of the flow of things

TODO: Refactor this code once the components group refactor @wix/wix-custom-element module
Components group ticket: https://jira.wixpress.com/browse/WCR-116
Our refactor ticket (blocked): https://jira.wixpress.com/browse/TB-3981
Original bug ticket: https://jira.wixpress.com/browse/TB-3909
*/

export const applyPolyfillsIfNeeded = () =>
	Promise.all([
		!('customElements' in window) &&
			// @ts-ignore
			import('@webcomponents/custom-elements' /* webpackChunkName: "custom-elements-polyfill" */),
		!('IntersectionObserver' in window) &&
			// @ts-ignore
			import('intersection-observer' /* webpackChunkName: "intersection-observer-polyfill" */),
		!('ResizeObserver' in window) &&
			import('@wix/wix-resize-observer-polyfill' /* webpackChunkName: "wix-resize-observer-polyfill" */).then(
				(ResizeObserverPolyfill) => (window.ResizeObserver = ResizeObserverPolyfill.default)
			),
	])

export const initWixCustomElementsRegistry = async (contextWindow: Window) => {
	const siteService = {
		getSiteScale: () => {
			const siteRootElement = document.querySelector<HTMLDivElement>('#site-root')
			if (siteRootElement) {
				return siteRootElement.getBoundingClientRect().width / siteRootElement.offsetWidth
			}
			return 1
		},
	}

	const resizeService = {
		// @ts-ignore
		init: (callback: any) => new contextWindow.ResizeObserver(callback),
	}

	const windowResizeService = {
		init: (callback: any) => contextWindow.addEventListener('resize', callback),
	}

	let wixCustomElements = {}
	if (process.env.browser) {
		const wixCustomElementsRegistry = await import('@wix/wix-custom-elements')
		wixCustomElements = wixCustomElementsRegistry.default.init(
			{
				resizeService,
				windowResizeService,
				siteService,
			},
			contextWindow
		)
	}

	return wixCustomElements
}
