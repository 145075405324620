// We need this CSS in the preview frame for panel builder but this css breaks the editor css

import { createPromise } from '@wix/thunderbolt-commons'

// We don't need this css for miniSites (for now) so we are not copying it.
const isDesignSystemCssHref = (href: string) => href.includes('rb_dsgnsys')
const isViewerCssHref = (href: string) => href.includes('viewer.css')
const isMainDsCssHref = (href: string) => href.includes('main-ds')

export const shouldExcludeStyleSheet = (href: string) =>
	isDesignSystemCssHref(href) || isMainDsCssHref(href) || isViewerCssHref(href)

export const VIEWER_ADDITIONAL_CSS = 'VIEWER_ADDITIONAL_CSS'
const pointerEventsCss =
	'[data-mesh-id$="-gridContainer"] > *, [data-mesh-id$="-rotated-wrapper"] > *, [data-mesh-id$="inlineContent"] > :not([data-mesh-id$="-gridContainer"]) { pointer-events: auto; }'

export const addViewerAdditionalCss = () => {
	const styleTag = document.createElement('style')
	styleTag.id = VIEWER_ADDITIONAL_CSS
	styleTag.appendChild(document.createTextNode(pointerEventsCss))
	window.top!.document.head.appendChild(styleTag)
}

export const loadStylesForFragment = () => {
	const loadStylesPromises: Array<Promise<any>> = []
	document.head.querySelectorAll('link[rel="stylesheet"]').forEach((e) => {
		const href = e.getAttribute('href')

		if (!href || shouldExcludeStyleSheet(href)) {
			return
		}
		if (!window.top!.document.head.querySelector(`link[href="${href}"]`)) {
			const newNode = e.cloneNode()
			const { promise, resolver } = createPromise()
			loadStylesPromises.push(promise)
			// @ts-ignore
			newNode.onload = resolver
			window.top!.document.head.appendChild(newNode)
		}
	})
	if (!window.top!.document.getElementById(VIEWER_ADDITIONAL_CSS)) {
		addViewerAdditionalCss()
	}

	return Promise.all(loadStylesPromises)
}
