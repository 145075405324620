import { BaseDataItem } from '@wix/thunderbolt-becky-types'
import { createDataNode } from '@wix/thunderbolt-catharsis'

export const RefArray = createDataNode({
	toViewItem: (__, { values }: { values: Array<BaseDataItem> }) => {
		const validValues = values.filter((v) => typeof v === 'object')
		// default should be first
		validValues.sort((a) => (a.type === 'VariantRelation' ? 1 : -1))
		return validValues
	},
})
