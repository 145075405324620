import { ViewerModel } from '@wix/thunderbolt-symbols'
import { BiModule } from '@wix/thunderbolt-environment'

export const initCustomElementsDS = async (viewerModel: ViewerModel, wixCustomElements: any) => {
	window.viewerModel = viewerModel
	const { experiments, media, requestUrl } = viewerModel

	const tbCustomElements = await import('@wix/thunderbolt-custom-elements')
	const biService = BiModule()
	tbCustomElements.initCustomElements({ experiments, media, requestUrl }, biService, wixCustomElements)
}
