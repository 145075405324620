import { CompNode } from '@wix/thunderbolt-catharsis'
import { breakpointsOrder } from '../responsiveLayout/breakpointsOrderCompNode'
import { compLayouts } from '../responsiveLayout/compLayoutCompNode'
import { pinnedLayer } from '../responsiveLayout/pinnedLayerCompNode'
import { responsiveLayout } from '../responsiveLayout/responsiveLayoutCompNode'
import { variants } from '../responsiveLayout/compVariantsCompNode'
import { componentCss } from '../css/componentCssCompNode'

export const componentNodes: Record<string, CompNode<any, any>> = {
	compLayouts,
	responsiveLayout,
	pinnedLayer,
	breakpointsOrder,
	variants,
	componentCss,
}
