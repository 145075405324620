import _ from 'lodash'
import React from 'react'
import ReactDOM from 'react-dom'
import { Container } from '@wix/thunderbolt-ioc'
import { Experiments, ExperimentsSymbol, LOADING_PHASES } from '@wix/thunderbolt-symbols'
import type { GetViewerApiParams, ViewerAPI } from 'thunderbolt-viewer-manager-types'
import { buildGetViewerFragment, buildGetViewerFragments } from './viewerFragmentRenderer'
import { DsApiFactoryEnv, getDsApis } from './getDsApis'
import { createLogger } from './logger/dsLogger'

// TODO Or Granit 29/09/2020: separate to stand alone features
import './style/ds.global.scss' // Only import it so it will be written in manifest.json
import { viewerFragmentsManagerFactory } from './viewerFragmentsManager'

declare const window: DsApiFactoryEnv['window']
export type DSViewerApiFactoryParams = GetViewerApiParams

const dsRunner = () => {
	// for editor-elements-registry
	window.define!('lodash', [], () => _)
	window.define!('reactDOM', [], () => ReactDOM)
	window.define!('react', [], () => React)

	window.externalsRegistry = {
		react: {
			loaded: Promise.resolve(),
			onload: _.noop,
		},
		lodash: {
			loaded: Promise.resolve(),
			onload: _.noop,
		},
		reactDOM: {
			loaded: Promise.resolve(),
			onload: _.noop,
		},
	}

	window.initialTimestamps = {
		initialTimestamp: 0,
		initialRequestTimestamp: 0,
	}

	const viewerManagerModulePromise = import(
		'thunderbolt-viewer-manager' /* webpackChunkName: "thunderboltViewerManager" */
	)
	const componentsPreviewRegistryCSRPromise = import(
		'@wix/thunderbolt-components-registry/preview' /* webpackChunkName: "thunderboltComponentsRegistryPreview" */
	)
	const createStylableFactoryPromise = import(
		'./createStylableFactory' /* webpackChunkName: "createStylableFactory" */
	)

	window.getViewerApi = async (params: DSViewerApiFactoryParams) => {
		const logger = createLogger({ params: { ...params, isViewerFragment: false } })
		logger.phaseStarted(LOADING_PHASES.GET_VIEWER_API)
		const initializeDsApis = (isViewerFragment: boolean) =>
			getDsApis(params, {
				window,
				logger: isViewerFragment ? createLogger({ params: { ...params, isViewerFragment: true } }) : logger,
				container: new Container(),
				createComponentsPreviewRegistryPromise: componentsPreviewRegistryCSRPromise,
				viewerManagerModulePromise,
				createStylableFactoryPromise,
				isViewerFragment,
			})

		const apis = await initializeDsApis(false)
		const experiments = apis.getModule<Experiments>(ExperimentsSymbol)
		const shouldUseViewerFragmentsManager = experiments['specs.thunderbolt.useViewerFragmentsManager']
		let viewerFragmentsManagerFunctions = {}

		// If the experiment is open, add the new functions to an object which will later
		// be joined with the `miniSites` API
		if (shouldUseViewerFragmentsManager) {
			const viewerFragmentsManager = viewerFragmentsManagerFactory(() => initializeDsApis(true), logger)

			viewerFragmentsManagerFunctions = {
				getIndependentViewerFragments: buildGetViewerFragments(() => initializeDsApis(true), logger),
				getMultipleViewerFragments: viewerFragmentsManager.getMultipleViewerFragments,
			}
		} else {
			viewerFragmentsManagerFunctions = {
				getViewerFragments: buildGetViewerFragments(() => initializeDsApis(true), logger),
			}
		}

		const miniSites: ViewerAPI['miniSites'] = {
			getViewerFragment: buildGetViewerFragment(() => initializeDsApis(true), logger),
			...viewerFragmentsManagerFunctions,
		}

		if (params.isResponsive) {
			const body = document.getElementsByTagName('body')[0] as HTMLElement
			body.classList.add('responsive')
		}

		const target = document.getElementById('SITE_CONTAINER') as HTMLElement
		logger.phaseStarted(LOADING_PHASES.INITIAL_DS_RENDER)
		await apis.render(target)
		logger.phaseEnded(LOADING_PHASES.INITIAL_DS_RENDER)
		apis.initCustomElements()

		const viewerAPI = { ...apis.getViewerAPI(), miniSites }
		logger.phaseEnded(LOADING_PHASES.GET_VIEWER_API)

		return viewerAPI
	}
}

dsRunner()
