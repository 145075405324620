import { Component, LayoutDataItems } from '@wix/thunderbolt-becky-types'
import { isSingleLayoutData, isVariantRelation, toVariantsString } from './responsiveLayoutUtils'
import { dataMapsRefs } from '../refs'
import { createCompNode } from '@wix/thunderbolt-catharsis'
import { BreakpointsVariantsToLayouts, BreakpointsVariantsToLayoutsMap } from '../types'

const getItemLayouts = (item: LayoutDataItems): Array<LayoutDataItems> => {
	if (!isSingleLayoutData(item)) {
		return [item]
	}
	const res = []
	if (item.componentLayout?.type) {
		res.push(item.componentLayout)
	}
	if (item.containerLayout?.type) {
		res.push(item.containerLayout)
	}
	if (item.itemLayout?.type) {
		res.push(item.itemLayout)
	}
	return res
}

export const compLayouts = createCompNode({
	getDependencies: (component: Component) =>
		component.layoutQuery ? dataMapsRefs.layout_data(component.layoutQuery) : null,
	toViewItem: (__, layoutRefArray): BreakpointsVariantsToLayouts => {
		if (!layoutRefArray || !Array.isArray(layoutRefArray)) {
			return null
		}

		const variantsToLayouts = layoutRefArray.reduce<BreakpointsVariantsToLayoutsMap>((acc, val) => {
			const isVariant = isVariantRelation(val)

			if (isVariant) {
				const [breakpointRange, ...variants] = val.variants
				const breakpointId = breakpointRange.type === 'BreakpointRange' ? breakpointRange.id : 'default'
				const variantsKey = toVariantsString(
					(breakpointId === 'default' ? val.variants : variants).map((v: any) => v.id)
				)
				acc[breakpointId] = acc[breakpointId] || {}
				acc[breakpointId][variantsKey] = acc[breakpointId][variantsKey] || []
				acc[breakpointId][variantsKey].push(...getItemLayouts(val.to))
				return acc
			}

			acc.default = acc.default || {}
			acc.default[''] = acc.default[''] || []
			acc.default[''].push(...getItemLayouts(val))

			return acc
		}, {})

		return variantsToLayouts
	},
})
