import { ProviderCreator } from '@wix/thunderbolt-ioc'
import type { DSCarmi } from 'thunderbolt-ds-carmi-root'
import { ViewerAPI } from '@wix/viewer-manager-interface'
import { ViewerManagerApiImplementor } from 'thunderbolt-viewer-manager-types'
import type { ViewerAPIProvider, IViewerManagerImplementor } from 'thunderbolt-viewer-manager-types'
import { createDefaultViewerAPI } from './defaultViewerAPI'
import { CarmiInstance } from '@wix/thunderbolt-symbols'

export const ViewerManagerProvider: ProviderCreator<ViewerAPIProvider> = (container) => {
	// TODO Or Granit 26/10/2020: Take all this logic and separate to feature/s
	const dsCarmi = container.get<DSCarmi>(CarmiInstance)

	// TODO: Remove once all the APIs are implemented using IViewerManagerImplementor
	const defaultApis = createDefaultViewerAPI(dsCarmi)

	return async () => ({
		getViewerAPI() {
			const allImplementors = container.getAll<
				IViewerManagerImplementor<keyof ViewerAPI, keyof ViewerAPI[keyof ViewerAPI]>
			>(ViewerManagerApiImplementor)

			return allImplementors.reduce<ViewerAPI>(
				(apis, implementor) =>
					Object.keys(implementor).reduce(
						(acc, namespace) => ({
							...acc,
							[namespace]: {
								...acc[namespace as keyof ViewerAPI],
								...implementor[namespace as keyof ViewerAPI],
							},
						}),
						apis
					),
				defaultApis as ViewerAPI
			)
		},
	})
}
