import { createRef, Ref } from '@wix/materializer'
import { Component } from '@wix/thunderbolt-becky-types'
import { Unref } from '../catharsis/catharsis.types'
import { ROOT } from '../refs/constants'

export type CompNode<TDependencies, TViewerItem> = {
	getDependencies: (structure: Readonly<Component>) => TDependencies
	toViewItem: (structure: Readonly<Component>, data: Unref<TDependencies>) => TViewerItem
}

export const createCompNode = <TDependencies, TViewerItem>(obj: CompNode<TDependencies, TViewerItem>) => obj

export const createCompRef = <T>(name: string, id: string, defaultValue?: T): Ref<T> =>
	createRef<T>([ROOT, name, id], defaultValue)
